<template>
  <div>
    <!-- İmg-->
    <b-img
      class="aboutImg mt-0"
      :src="imgDataUrl"
      fluid
      alt="Responsive image"
    />
    <b-tabs align="center" pills class="nav-pill-secondary mt-1">
      <b-tab :title="getTabTitle('HAKKIMIZDA')" class="tabButton">
        <b-card-text>
          <br /><br />
          <b-row>
            <b-col md="1"></b-col>
            <b-col md="10">
              <hakkimizda
                v-if="groupedData && allDatas"
                :hakkimizdaData="groupedData[12]"
              ></hakkimizda
            ></b-col>
            <b-col md="1"></b-col>
          </b-row>
        </b-card-text>
      </b-tab>

      <b-tab
        class="tabButton"
        :title="getTabTitle('VİZYON, MİSYON ve FARKIMIZ')"
      >
        <b-card-text>
          <br /><br />
          <b-row v-if="groupedData && allDatas">
            <b-col md="1"></b-col>
            <b-col md="10"
              ><vizyon :VizyonAboutData="groupedData[13]"></vizyon
            ></b-col>
            <b-col md="1"></b-col
          ></b-row>
        </b-card-text>
      </b-tab>

      <b-tab
        class="tabButton"
        :title="getTabTitle('ENERJİ VE ÇEVRE YAKLAŞIMI')"
      >
        <b-card-text>
          <br /><br />
          <b-row>
            <b-col md="1"></b-col>
            <b-col md="10"
              ><enerji
                v-if="groupedData && allDatas"
                :enerjiData="groupedData[14]"
              ></enerji
            ></b-col>
            <b-col md="1"></b-col
          ></b-row>
        </b-card-text>
      </b-tab>
    </b-tabs>
    <!-- Footer -->
    <footer-vue :AdressData="AdressData"> </footer-vue>
  </div>
</template>

<script>
import { BTabs, BTab, BRow, BCol, BCardText, BImg } from "bootstrap-vue";
import hakkimizda from "./hakkimizda.vue";
import Vizyon from "./Vizyon.vue";
import enerji from "./enerji.vue";
import axios from "axios";
import footerVue from "../home/components/footer.vue";
export default {
  components: {
    BTabs,
    BTab,
    BRow,
    BCardText,
    BCol,
    hakkimizda,
    Vizyon,
    enerji,
    BImg,
    footerVue,
  },

  data() {
    return {
      imgDataUrl: "",
      isActiveTab: "allPost",
      activeBlogId: null,
      activeAllPost: null,
      allDatas: null,
      groupedData: null,
      AdressData: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    toggleActiveTab() {
      this.isActiveTab = !this.isActiveTab;
    },
    changeActiveTab(id, tabs) {
      this.activeBlogId = { id, tabs };
    },
    changeTab(tabName) {
      this.isActiveTab = tabName;
    },
    
    // get method
    getData() {
      // console.log("GET DATA");
      this.groupedData = [];
      const languageCode = localStorage.getItem("language") === "2" ? 2 : 1;
      axios
        .get(
          `/api/Contents/GetListByPageIdAndLanguage?id=6&language=${languageCode}`
        )
        .then((result) => {
          this.getCommunications();
          if (result.data.success) {
            // console.log("BURADA LOGA BAK");
            //    console.log(result.data.data);
            this.allDatas = result.data.data;
            this.splitData(result.data.data);
            //   console.log(this.allDatas[0]);
          } else {
            console.error("API yanıtında başarısızlık:", result.data.message);
          }
        })
        .catch((error) => {
          console.error("Catch'e düştü", error);
        });
    },
    splitData(myData) {
      myData.forEach((item) => {
        //  console.log("fdgdfgd : ",item)
        const componentId = item.componentId;
        if (!this.groupedData[componentId]) {
          this.groupedData[componentId] = [];
        }
        this.groupedData[componentId].push(item);
      });
      //  console.log("group data" + this.groupedData);
      this.imgDataUrl = this.groupedData[11][0].image;
    },
    getCommunications() {
      const languageCode = localStorage.getItem("language") === "2" ? 2 : 1;
      axios
        .get(`/api/Informations/GetListByLanguage?language=${languageCode}`)
        .then((result) => {
          if (result.data.success) {
            this.AdressData = result.data.data;
            //   console.log(this.AdressData)
          } else {
            console.error("API yanıtında başarısızlık:", response.data.message);
          }
        })
        .catch((error) => {
          console.error("Catch'e düştü", error);
        });
    },
    getTabTitle(tabKey) {
      return localStorage.getItem("language") === "2"
        ? this.getEnglishText(tabKey)
        : this.getDefaultText(tabKey);
    },
    getEnglishText(tabKey) {
      switch (tabKey) {
        case "HAKKIMIZDA":
          return "ABOUT US";
        case "VİZYON, MİSYON ve FARKIMIZ":
          return "VISION, MISSION, AND DIFFERENCE";
        case "ENERJİ VE ÇEVRE YAKLAŞIMI":
          return "ENERGY AND ENVIRONMENTAL APPROACH";
        default:
          return tabKey; // If no translation is available, return the original tab key
      }
    },
    getDefaultText(tabKey) {
      return tabKey; // Use the tab key as the default text
    },
  },
  watch: {
    activeBlogId(yeni, eski) {
      if (yeni && yeni.tabs) {
        this.isActiveTab = yeni.tabs;
        // console.log("if e girmiş", this.isActiveTab);
      }
      // console.log("if den çıkmış");
    },
  },
};
</script>
<style scoped>
.tabButton {
  font-family: "Prompt", sans-serif;
  border: 1px solid;
  background-color: #0725419a;
}
.aboutImg {
  width: 100%;
  height: 700px;
  object-fit: cover;
  /*object-position: 50% 50%;*/
}

@media (max-width: 1000px) {
  .nav-pill-secondary {
    margin-left: 0px;
    margin-top: 20px;
  }
  .aboutImg {
    height: auto;
    /*object-position: 50% 50%;*/
  }
}
</style>
